import React from "react"
import Layout from "../components/layout/Layout"
import styles from "../styles/text-section.module.scss"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import { Helmet } from "react-helmet"
import BenefitsSection from "../components/landing/benefits"

export default function PadanguMontavimas() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Montuojame padangas, atliekame ratų balansavimą bei remontą.
        </title>
        <meta
          name="description"
          content="Reikalinga ratų apžiūra? Mūsų servise Kaune atliekamas padangų keitimas, padangų remontas, ratų montavimas, ratų balansavimas."
        ></meta>
      </Helmet>
      <section className={`${styles.textSection} container`}>
        <h1>Padangų montavimas ir balansavimas</h1>
        <p>
          Tinkamos padangos yra viena svarbiausių saugumo kelyje garantijų.
          Padangų montavimas yra automobilio techninės priežiūros dalis, kuri
          priklauso sezoniniam automobilio paruošimui. Mūsų meistrai atlieka
          visapusišką ratų priežiūrą: montuoja padangas, atlieka ratų
          balansavimą bei padangų remontą.
        </p>
        <p>
          Būkite pasiruošę ir saugūs kelyje - nuo padangų būklės priklauso
          automobilio stabilumas, stabdymo kelio ilgis ir galimybės išvengti
          skaudžių eismo įvykių. Padangų montavimas dažniausiai reikalingas ir
          yra vykdomas Kelių eismo taisyklių numatyta tvarka rudenį ir pavasarį.
          Nuo lapkričio 10 d. turi būti sumontuotos žieminės arba universalios
          padangos, nuo balandžio 1 d. – vasarinės. Rezervuokite laiką iš
          anksto.
        </p>
        <p>
          Padangų remontas reikalingas esant įplyšimams, taip pat joms
          prakiurus. Atliksime padangų išorės ir vidaus patikrą, kad
          įsitikintume ar nėra didelių nukrypimų nuo normos. Rekomenduojama
          nevažinėti su nudėvėtomis padangomis, kadangi labai svarbu, kad jos
          būtų geros būklės ir su reikiamo gylio protektoriumi.
        </p>
      </section>
      <ContactForm />
      <section className={`${styles.textSection} container`}>
        <h2>Darbų kainos</h2>
        <table>
          <tbody>
            <tr>
              <td>1</td>
              <td>Rato nuėmimas/pastatymas</td>
              <td>nuo 2 eur</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Rato montavimas</td>
              <td>nuo 3 eur</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Rato balansavimas</td>
              <td>2 eur</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Ventilis/pakeitimas</td>
              <td>nuo 2 eur</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Padangos remontas</td>
              <td>nuo 5 eur</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Padangos išmontavimas</td>
              <td>nuo 2 eur</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Padangos sumontavimas</td>
              <td>nuo 2 eur</td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                Ratų montavimas / balansavimas ( 4 vnt.) Metaliniai diskai
              </td>
              <td>22 eur</td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                Ratų montavimas / balansavimas ( 4 vnt.) Lieti diskai iki R16
              </td>
              <td>24 eur</td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                Ratų montavimas / balansavimas ( 4 vnt.) mikroautobusams,
                džipams
              </td>
              <td>nuo 25 eur</td>
            </tr>
            <tr>
              <td>11</td>
              <td>Ratų balansavimas / montavimas ( 4 vnt.) 15-18 diametro</td>
              <td>nuo 25 eur</td>
            </tr>
          </tbody>
        </table>
      </section>
      <BenefitsSection />
    </Layout>
  )
}
